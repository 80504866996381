import { createApp } from 'vue'
import { createMetaManager } from 'vue-meta'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import '@/assets/tailwind.css'

const app = createApp(App)

app.use(createMetaManager())
app.use(router)
app.use(store)
app.mount('#app')
